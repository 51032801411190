import { ISellItem, TInventoryItemNew } from '@/componentTypes/inventory';
import { AlternativeBaseItem, AlternativeItem } from '@/generated/projectlb-hasura';

export type IframeModalParams = {
  iframeUrl: string
};

export type UnavailableSkinModalParams = {
  typeContent: 'loading' | 'unav' | 'status' | 'none';
  serverData?: {
    alternative_items: AlternativeItem[];
    error: string;
    base_item: AlternativeBaseItem;
  },
  handleTradeItem?: (
    initialItem: TInventoryItemNew,
    isModalRequest: boolean,
    alternativeItem?: AlternativeItem | undefined
  ) => Promise<void>;
  handleSellItem?: (params: ISellItem) => Promise<void>;
  sendDataLoading?: boolean;
  loadingSellItem?: boolean;
  statusContent?: {
    headingText: string;
    subtext: string;
    success: boolean;
  }
};

export const MODAL_NAME = {
  CLEAR: 'clear',
  WELCOME: 'welcome',
  SBP_IFRAME: 'sbp_iframe',
  UNAVAILABLE_SKIN: 'unavailable_skin',
  NO_AUTH_FORM: 'no_auth_form',
  PAY_MODAL: 'pay_modal'
} as const;

export type TModalKind = typeof MODAL_NAME[keyof typeof MODAL_NAME];

export type TModalParams = IframeModalParams | UnavailableSkinModalParams | null;

export type ModalContextType = {
  setModalName: (modalName: TModalKind) => void;
  modalParams: TModalParams,
  setModalParams: (modalParams: TModalParams) => void;
};