// Типы для глобального объекта window.posthog
declare global {
  interface Window {
    posthog?: {
      capture: (eventName: string, properties?: Record<string, any>) => void;
      identify: (distinctId: string, properties?: Record<string, any>) => void;
      reset: () => void;
      register: (properties: Record<string, any>) => void;
      alias: (alias: string, distinctId?: string) => void;
      init: (apiKey: string, options?: any) => void;
      [key: string]: any;
    };
  }
}

/**
 * Отправляет событие в PostHog
 * @param eventName Название события
 * @param properties Свойства события
 */
export const captureEvent = (eventName: string, properties?: Record<string, any>): void => {
  if (typeof window !== 'undefined' && window.posthog) {
    window.posthog.capture(eventName, properties);
  }
};

/**
 * Идентифицирует пользователя в PostHog
 * @param distinctId Уникальный идентификатор пользователя
 * @param properties Свойства пользователя
 */
export const identifyUser = (distinctId: string, properties?: Record<string, any>): void => {
  if (typeof window !== 'undefined' && window.posthog) {
    window.posthog.identify(distinctId, properties);
  }
};

/**
 * Сбрасывает идентификацию пользователя в PostHog
 */
export const resetUser = (): void => {
  if (typeof window !== 'undefined' && window.posthog) {
    window.posthog.reset();
  }
};

/**
 * Регистрирует свойства для всех последующих событий
 * @param properties Свойства для регистрации
 */
export const registerProperties = (properties: Record<string, any>): void => {
  if (typeof window !== 'undefined' && window.posthog) {
    window.posthog.register(properties);
  }
};

/**
 * Создает псевдоним для пользователя
 * @param alias Псевдоним
 * @param distinctId Уникальный идентификатор пользователя
 */
export const aliasUser = (alias: string, distinctId?: string): void => {
  if (typeof window !== 'undefined' && window.posthog) {
    window.posthog.alias(alias, distinctId);
  }
};

export default {
  captureEvent,
  identifyUser,
  resetUser,
  registerProperties,
  aliasUser
};
