import { signInType } from '@/configs/urls';
import { useAuthContext } from '@/hooks/use-auth-context';
import { passAmpliSessionIdToCookie } from '@/utils/ampli-session-cookie';
import { setReturnPageCookie } from '@/utils/set-return-page-cookie';
import { track } from '@amplitude/analytics-browser';
import Image from 'next/image';
import { useRouter } from 'next/router';
import styles from './LoginButton.module.scss';
import { useEffect, useRef, useState } from 'react';
import { useWheelUserTicketInfoLazyQuery } from '@/generated/wheel.generated';
import { captureEvent } from '@/utils/analytics';

const telegramAuthBotLogin = process.env.NEXT_PUBLIC_TELEGRAM_AUTH_BOT_LOGIN;

const LoginButton = ({
  from,
  darkBg,
  openPayModal,
}: {
  from: string;
  darkBg?: boolean;
  openPayModal?: boolean;
}) => {
  const router = useRouter();
  const { isAuthorized, accessToken } = useAuthContext();
  const [WheelUserTicketInfoQuery] = useWheelUserTicketInfoLazyQuery();
  const telegramContainerRef = useRef<HTMLDivElement>(null);
  const [isTelegramAvailable, setIsTelegramAvailable] = useState(false);

  useEffect(() => {
    if (isAuthorized && accessToken) {
      WheelUserTicketInfoQuery({
        context: { token: accessToken },
      });
    }
  }, [isAuthorized, accessToken]);

  useEffect(() => {
    if (!telegramAuthBotLogin) {
      setIsTelegramAvailable(false);
      return;
    }

    const checkTelegramScript = () => {
      try {
        const script = document.createElement('script');
        script.src = 'https://telegram.org/js/telegram-widget.js?22';
        script.onload = () => {
          setIsTelegramAvailable(true);
        };
        script.onerror = () => {
          setIsTelegramAvailable(false);
        };
        document.head.appendChild(script);

        // Устанавливаем таймаут для проверки
        const timeout = setTimeout(() => {
          if (!document.querySelector('script[src*="telegram-widget.js"]')) {
            setIsTelegramAvailable(false);
          }
        }, 3000);

        return () => {
          clearTimeout(timeout);
          script.remove();
        };
      } catch (error) {
        setIsTelegramAvailable(false);
      }
    };

    checkTelegramScript();
  }, []);

  // Инициализация виджета Telegram при монтировании компонента
  useEffect(() => {
    if (!isTelegramAvailable || !telegramContainerRef.current || !telegramAuthBotLogin) {
      return;
    }

    // Очищаем контейнер
    telegramContainerRef.current.innerHTML = '';

    try {
      // Создаем скрипт
      const script = document.createElement('script');
      script.src = 'https://telegram.org/js/telegram-widget.js?22';
      script.setAttribute('data-telegram-login', telegramAuthBotLogin);
      script.setAttribute('data-size', 'large');
      script.setAttribute('data-radius', '12');
      script.setAttribute('data-auth-url', '/process_sign_in_telegram');
      script.setAttribute('data-request-access', 'write');
      script.async = true;

      // Добавляем скрипт в контейнер
      telegramContainerRef.current.appendChild(script);
    } catch (error) {
      console.error('Ошибка при инициализации Telegram виджета:', error);
      setIsTelegramAvailable(false);
    }
  }, [isTelegramAvailable]);

  const handleClick = (name: string, href: string) => {
    if (name !== 'telegram') {
      setReturnPageCookie(router.asPath, openPayModal);
      passAmpliSessionIdToCookie();
      captureEvent('sign_in start', {
        type: name,
        auser: isAuthorized,
        from: from,
      });
      track('sign_in start', {
        type: name,
        auser: isAuthorized,
        from: from,
      });
      router.push(href);
    }
  };

  return (
    <div
      className={styles.container}
      style={{ background: darkBg ? '#0c1027' : '#191f40' }}
    >
      <p className={styles.text}>Войти</p>
      <div className={styles.type_login}>
        {signInType.map((type, index) => {
          if (type.name === 'telegram') {
            // Не отображаем кнопку Telegram, если авторизация недоступна
            if (!isTelegramAvailable) {
              return null;
            }

            return (
              <div
                key={index}
                className={styles.telegramContainer}
                ref={telegramContainerRef}
              />
            );
          }

          return (
            <button
              key={index}
              onClick={() => handleClick(type.name, type.href)}
              style={{ background: darkBg ? '#191f40' : '#0c1027' }}
            >
              <Image src={type.icon} alt={`Вход ${type.name}`} />
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default LoginButton;
