import { theme } from '@/styles/theme';
import { useMediaQuery } from '@mui/material';
import styles from './MainPageCards.module.scss';
import { useEffect, useState } from 'react';
import newHDCoin from '@/assets/images/mainBackground/mainPageCards/newHDCoin.webp';
import newUpgrade from '@/assets/images/mainBackground/mainPageCards/newUpgrade.webp';
import newWheel from '@/assets/images/mainBackground/mainPageCards/newWheel.webp';
import newGiveaway from '@/assets/images/mainBackground/mainPageCards/newGiveaway.webp';
import store from '@/assets/images/mainBackground/mainPageCards/store.webp';
import { missionCountAtom } from 'atoms/mission-count-atom';
import { useAtomValue } from 'jotai';
import { useBalance } from '@/hooks/use-balance';
import { APP_URLS } from '@/configs/urls';
import { useRouter } from 'next/router';
import BannerCard from '../BannerCards/BannerCards';
import { useAuthContext } from '@/hooks/use-auth-context';
import { useClosestGiveawayLazyQuery } from '@/generated/projectlb-hasura';
import { useWheelUserTicketInfoLazyQuery } from '@/generated/wheel.generated';
import { StaticImageData } from 'next/image';
import dynamic from 'next/dynamic';

const DynamicPayModal = dynamic(
  () => import('@/components/Pay/PayModal/PayModal'),
  {
    ssr: false,
  }
);

export interface BannerCardsInfo {
  type: 'banner' | 'wheel' | 'mission' | 'upgrade' | 'store' | 'giveaway';
  title: string;
  description: string;
  buttonText?: string;
  img?: StaticImageData;
  link?: string;
  link2?: string;
}

const initBannerCardsInfo: BannerCardsInfo[] = [
  {
    type: 'banner',
    title: 'Забирай топ-дроп!',
    description: 'В лучшем кейсе\nпо мнению наших юзеров.',
    buttonText: 'Открыть',
  },
  {
    type: 'wheel',
    title: `Барабан`,
    description: 'Крути барабан, и забирай призы',
    img: newWheel,
    link: APP_URLS.WHEEL_PAGE,
  },
  {
    type: 'mission',
    title: 'Халява',
    description: 'Выполняй миссии и зарабатывай HD coin',
    img: newHDCoin,
    link: APP_URLS.WELCOME_PAGE,
    link2: APP_URLS.MISSIONS_PAGE,
  },
  {
    type: 'upgrade',
    title: 'Upgrade',
    description: 'Из 💩 в 🍬\nза один клик',
    img: newUpgrade,
    link: APP_URLS.UPGRADE_PAGE,
  },
  {
    type: 'store',
    title: 'Магазин',
    description: 'Покупки за HD Coins',
    img: store,
    link: APP_URLS.STORE,
  },
  {
    type: 'giveaway',
    title: 'Giveaway',
    description: 'Раздача игровых призов',
    img: newGiveaway,
    link: APP_URLS.GIVE_PAGE,
  },
];

const MainPageCards = () => {
  const { accessToken } = useAuthContext();
  const isDesktop = useMediaQuery(theme.breakpoints.up(1024));
  const router = useRouter();
  const currentUrl = router.pathname;
  const [isPayModalOpen, setIsPayModalOpen] = useState(false);
  const [bannerCardsInfo, setBannerCardsInfo] = useState(initBannerCardsInfo);

  const [getWheelUserTickets, { data: WheelUserTicketInfo }] =
    useWheelUserTicketInfoLazyQuery();

  const [getClosestGiveaway, { data: ClosestGiveaway }] =
    useClosestGiveawayLazyQuery();

  useEffect(() => {
    getClosestGiveaway();
  }, [currentUrl]);

  useEffect(() => {
    getWheelUserTickets({
      fetchPolicy: 'cache-and-network',
      context: { token: accessToken },
    });
  }, [accessToken]);

  const missionsCount = useAtomValue(missionCountAtom);
  const { bonusBalance } = useBalance();
  
  useEffect(() => {
    if(isDesktop) {
      setBannerCardsInfo(initBannerCardsInfo);
    } else {
      setBannerCardsInfo(initBannerCardsInfo.filter((card) => card.type === 'banner'));
    }
  }, [isDesktop]);

  return (
    <div
      className={
        router.pathname === '/'
          ? styles.bigCards
          : styles.smallCards
      }
    >
      {bannerCardsInfo.map((card, index) => (
        <BannerCard
          key={card.title}
          card={card}
          index={index}
          missionsCount={missionsCount}
          bonusBalance={bonusBalance}
          ticketsInfo={WheelUserTicketInfo?.WheelUserTicketInfo?.tickets}
          closestGiveaway={ClosestGiveaway?.ClosestGiveaway?.giveaway}
        />
      ))}
      <DynamicPayModal
        open={isPayModalOpen}
        close={() => setIsPayModalOpen(false)}
        pmName="Международные карты(кроме BY/UA)"
      />
    </div>
  );
};

export default MainPageCards;
