import React, { createContext, useContext, useState, useCallback, ReactNode } from "react";
import dynamic from 'next/dynamic';
import { MODAL_NAME, ModalContextType, TModalKind, TModalParams } from '@/componentTypes/modals';

const ModalContext = createContext<ModalContextType | undefined>(undefined);

const WelcomeBannerModal = dynamic(
  () => import('@/components/modals/WelcomeBannerModal/WelcomeBannerModal'),
  { ssr: false }
);

const SpbIframeModal = dynamic(
  () => import('@/components/modals/SpbIframeModal/SpbIframeModal'),
  { ssr: false }
);

const UnavailableSkin = dynamic(
  () => import('@/components/modals/UnavailableSkin/UnavailableSkin'),
  { ssr: false }
);

const NoAuthFormModal = dynamic(
  () => import('@/components/modals/NoAuthFormModal/UnauthFormModal'),
  { ssr: false }
);

const PayModal = dynamic(
  () => import('@/components/modals/PayModal/PayModal'),
  { ssr: false }
);

const MODALS: { [key in TModalKind]: JSX.Element | null } = {
    [MODAL_NAME.CLEAR]: null,
    [MODAL_NAME.WELCOME]: <WelcomeBannerModal />,
    [MODAL_NAME.SBP_IFRAME]: <SpbIframeModal />,
    [MODAL_NAME.UNAVAILABLE_SKIN]: <UnavailableSkin />,
    [MODAL_NAME.NO_AUTH_FORM]: <NoAuthFormModal />,
    [MODAL_NAME.PAY_MODAL]: <PayModal />
};

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [modalName, setModalName] = useState<TModalKind>(MODAL_NAME.CLEAR);
    const [modalParams, setModalParams] = useState<TModalParams>(null);

    const handleModalType = useCallback(
      (modalName: TModalKind) => {
          setModalName(modalName);
      },
      []
    );

    return (
        <ModalContext.Provider value={{ setModalName: handleModalType, modalParams, setModalParams }}>
            {children}
            {MODALS[modalName]}
        </ModalContext.Provider>
    );
};

export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error("useModal must be used within a ModalProvider");
    }
    return context;
};
