import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';
import styles from './IconButton.module.scss';
import HyperRub from '@/assets/icons/hyperRub';

type IIconButton = ButtonProps & {
  loading?: boolean;
  className?: string;
  icon: JSX.Element;
  text: string;
  iconSecondary?: JSX.Element;
  dark?: boolean;
  wallet?: boolean;
};

export default function IconButton({
  loading,
  className,
  icon,
  text,
  iconSecondary,
  dark,
  wallet,
  ...props
}: IIconButton) {
  return (
    <Button
      className={`${dark ? styles.dark : styles.default} ${className}`}
      disabled={props.disabled || loading == true}
      style={loading ? { justifyContent: 'center' } : undefined}
      {...props}
    >
      {loading ? (
        <CircularProgress
          style={{
            color: '#0C1027',
          }}
        />
      ) : (
        <>
          {icon}
          <p className={styles.button_text}>
            {text} {wallet ? <HyperRub color="#0C1027" /> : ''}
          </p>

          {iconSecondary && (
            <div className={styles.oval_container}>{iconSecondary}</div>
          )}
        </>
      )}
    </Button>
  );
}
