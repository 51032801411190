'use client'

import React, { createContext, useContext, useState, ReactNode, useEffect, useMemo } from 'react';
import { useModal } from '@/utils/contexts/ModalProvider';
import { useRouter } from 'next/router';
import { promoStorage } from '@/utils/new-user-promo';
import {
  GetUserTermsAndDeposits,
} from '@/generated/account.generated';
import client from '../../../apollo-client';
import { MODAL_NAME } from '@/componentTypes/modals';

type ModalContextType = {
  isActive: boolean;
  isActiveWelcomeMission: boolean;
  timeLeft: number;
  formattedTime: string;
  initPromoTimer: () => void;
  turnOffWelcomeADS: () => void;
};
let queryCount = 2;

const MotivationMissionsContext = createContext<ModalContextType | undefined>(undefined);
export const MotivationMissions: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { setModalName } = useModal();
  const router = useRouter();

  const [timeLeft, setTimeLeft] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isActiveWelcomeMission, setIsActiveWelcomeMission] = useState<boolean>(false);
  const [depositCount, setDepositCount] = useState(0);
  const [queryDone, setQueryDone] = useState(false);

  const checkTime = () => {
    const remaining = promoStorage.getTimeLeft();
    const remainingTimeWelcomeMission = Number(promoStorage.getModalWelcomeMissionTimeLeft());
    const params = new URLSearchParams(window.location.search);
    const modal = params.get('modal');

    if(queryDone) {
      if (remaining > 0 && depositCount <= 0) {
        setTimeLeft(remaining);
        setIsActive(true);
      } else {
        setTimeLeft(0);
        setIsActive(false);
        sessionStorage.removeItem('local_pm_data')
      }
    }

    if (isActiveWelcomeMission) {
      if(modal === 'payment') {
        promoStorage.setTimeWelcomeMissionPromo(remainingTimeWelcomeMission || 0);
      } else {
        promoStorage.setTimeWelcomeMissionPromo(remainingTimeWelcomeMission - 1);
      }

      if (remainingTimeWelcomeMission <= 0 && modal !== 'payment') {
        setModalName(MODAL_NAME.WELCOME);
        turnOffWelcomeADS();
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(checkTime, 1000);

    return () => clearInterval(timer);
  }, [isActiveWelcomeMission, depositCount, queryDone]);

  useEffect(() => {
    if(promoStorage.getModalWelcomeMissionTimeLeft() > 0) {
      setIsActiveWelcomeMission(true);
    }
    callQueryDepositCount()
  }, []);

  const formattedTime = useMemo(() => {
    if (!isActive) return '';

    const timeLeftAdjusted = timeLeft - 10 * 60 * 1000;
    if (timeLeftAdjusted < 0) return '';

    const minutes = Math.floor(timeLeftAdjusted / (60 * 1000));
    const seconds = Math.floor((timeLeftAdjusted % (60 * 1000)) / 1000);

    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }, [isActive, timeLeft]);

  const turnOffWelcomeADS = () => {
    setIsActiveWelcomeMission(false);
    promoStorage.turnOffWelcomeMissionPromo();
  }

  const initPromoTimer = () => {
    promoStorage.initNewUserPromo();
    promoStorage.initWelcomeMissionPromo();
    setIsActiveWelcomeMission(true);
    callQueryDepositCount();
    sessionStorage.setItem(
      'local_pm_data',
      JSON.stringify({
        local_pay: 300,
        local_promo: promoStorage.code,
        local_type_currency: 0,
        local_type_pay: 0,
      })
    );

    router.push({
      pathname: router.pathname,
      query: { ...router.query, modal: 'payment' },
    });
  };

  const callQueryDepositCount = async () => {
    try {
      const hdToken = localStorage.getItem('hd_token') || '';
      const accessToken = JSON.parse(hdToken).accessToken;
      const { data } = await client.query({
        query: GetUserTermsAndDeposits,
        context: { token: accessToken },
        fetchPolicy: 'network-only'
      });

      if (data?.Profile__UserTermsAndDeposits?.error?.length > 0 && queryCount > 0) {
        queryCount--;
        setTimeout(async () => {
          await callQueryDepositCount();
        }, 1000);
      }
      setDepositCount(data?.Profile__UserTermsAndDeposits?.total_deposits_count || 0);
      setQueryDone(true);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
    }
  };

  const contextValue = useMemo(
    () => ({
      isActive,
      isActiveWelcomeMission,
      timeLeft,
      formattedTime,
      initPromoTimer,
      turnOffWelcomeADS,
    }),
    [isActive, isActiveWelcomeMission, timeLeft, formattedTime]
  );

  return (
    <MotivationMissionsContext.Provider value={contextValue}>
      {children}
    </MotivationMissionsContext.Provider>
  );
};

export const useMotivationMissions = (): ModalContextType => {
  const context = useContext(MotivationMissionsContext);
  if (!context) {
    throw new Error("useMotivationMissions must be used within a MotivationMissionsProvider");
  }
  return context;
};
