import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProfileUserLastOpenedLootboxQueryVariables = Types.Exact<{
  user_id: Types.Scalars['uuid'];
}>;

export type ProfileUserLastOpenedLootboxQuery = {
  __typename?: 'query_root';
  Profile__UserLastOpenedLootbox?: {
    __typename?: 'UserLastOpenedLootboxResponse';
    success: boolean;
    error?: string | null;
    user_last_opened_lootbox?: {
      __typename?: 'Lootbox';
      lootbox_id: string;
      lootbox_name: string;
      lootbox_image_link: string;
      lootbox_price: number;
      lootbox_slug?: string | null;
    } | null;
  } | null;
};

export type ProfileUserFavoriteLootboxesQueryVariables = Types.Exact<{
  user_id: Types.Scalars['uuid'];
  limit: Types.Scalars['Int'];
}>;

export type ProfileUserFavoriteLootboxesQuery = {
  __typename?: 'query_root';
  Profile__UserFavoriteLootboxes?: {
    __typename?: 'UserFavoriteLootboxesResponse';
    error?: string | null;
    success: boolean;
    user_favorite_lootboxes?: Array<{
      __typename?: 'Lootbox';
      lootbox_id: string;
      lootbox_name: string;
      lootbox_image_link: string;
      lootbox_price: number;
      lootbox_slug?: string | null;
    } | null> | null;
  } | null;
};

export type ProfileUserTopDropsQueryVariables = Types.Exact<{
  user_id: Types.Scalars['uuid'];
  limit: Types.Scalars['Int'];
}>;

export type ProfileUserTopDropsQuery = {
  __typename?: 'query_root';
  Profile__UserTopDrops?: {
    __typename?: 'UserTopDropsResponse';
    error?: string | null;
    success: boolean;
    user_top_drops?: Array<{
      __typename?: 'Drop';
      item_hash_name: string;
      item_id: string;
      item_image_link: string;
      item_price: number;
      lootbox_id: string;
      lootbox_slug: string;
      lootbox_name: string;
      lootbox_image_link: string;
      item_rarity: string;
      item_quality: string;
      ru_name: string;
    } | null> | null;
  } | null;
};

export type ProfileUserItemsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
  status: Types.Scalars['Int'];
}>;

export type ProfileUserItemsQuery = {
  __typename?: 'query_root';
  Profile__UserItems: {
    __typename?: 'UserItemsResponse';
    error?: string | null;
    success: boolean;
    available_count: number;
    unavailable_count: number;
    user_items?: Array<{
      __typename?: 'ItemInfo';
      from_upgrade: boolean;
      item_frozen: boolean;
      item_hash_name: string;
      item_id: string;
      user_item_id: string;
      item_image_link: string;
      item_lootbox_id: string;
      item_quality: string;
      item_rarity: string;
      item_lootbox_name: string;
      item_lootbox_slug: string;
      item_lootbox_image_link: string;
      item_start_price: number;
      item_status: number;
      ru_name?: string | null;
      upgrade_serial_data?: {
        __typename?: 'UpgradeSerialData';
        count: number;
        user_prev_item_id: string;
        user_start_item_id: string;
        user_start_item_price: number;
      } | null;
    } | null> | null;
  };
};

export type ProfileUserBoostersQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type ProfileUserBoostersQuery = {
  __typename?: 'query_root';
  Profile__UserBoosters?: {
    __typename?: 'UserBoostersResponse';
    error?: string | null;
    success: boolean;
    user_boosters: Array<{
      __typename?: 'UserBooster';
      booster_count: number;
      booster_id: string;
      booster_image_link: string;
      booster_title: string;
      booster_value: number;
    } | null>;
  } | null;
};

export type ProfileUpgradeHistoryQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
}>;

export type ProfileUpgradeHistoryQuery = {
  __typename?: 'query_root';
  Profile__UpgradeHistory: {
    __typename?: 'UserUpgradeHistoryResponse';
    error?: string | null;
    success: boolean;
    upgrade_history: Array<{
      __typename?: 'UpgradeHistory';
      chance: number;
      koef: number;
      result: boolean;
      start_item_id: string;
      target_item_id: string;
      target_item_price: number;
      boosters?: Array<{
        __typename?: 'AppliedBooster';
        booster_id: string;
        booster_title?: string | null;
        booster_image_link?: string | null;
      } | null> | null;
      start_item_data: {
        __typename?: 'HistoryItem';
        item_hash_name: string;
        item_image_link_formated: string;
        item_quality: string;
        item_rarity: string;
        item_start_price: string;
        ru_name: string;
      };
      target_item_data: {
        __typename?: 'HistoryItem';
        item_hash_name: string;
        item_image_link_formated: string;
        item_quality: string;
        item_rarity: string;
        item_start_price: string;
        ru_name: string;
      };
    } | null>;
  };
};

export const ProfileUserLastOpenedLootboxDocument = gql`
  query ProfileUserLastOpenedLootbox($user_id: uuid!) {
    Profile__UserLastOpenedLootbox(user_id: $user_id) {
      success
      error
      user_last_opened_lootbox {
        lootbox_id
        lootbox_name
        lootbox_image_link
        lootbox_price
        lootbox_slug
      }
    }
  }
`;

/**
 * __useProfileUserLastOpenedLootboxQuery__
 *
 * To run a query within a React component, call `useProfileUserLastOpenedLootboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileUserLastOpenedLootboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileUserLastOpenedLootboxQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useProfileUserLastOpenedLootboxQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProfileUserLastOpenedLootboxQuery,
    ProfileUserLastOpenedLootboxQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProfileUserLastOpenedLootboxQuery,
    ProfileUserLastOpenedLootboxQueryVariables
  >(ProfileUserLastOpenedLootboxDocument, options);
}
export function useProfileUserLastOpenedLootboxLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfileUserLastOpenedLootboxQuery,
    ProfileUserLastOpenedLootboxQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProfileUserLastOpenedLootboxQuery,
    ProfileUserLastOpenedLootboxQueryVariables
  >(ProfileUserLastOpenedLootboxDocument, options);
}
export type ProfileUserLastOpenedLootboxQueryHookResult = ReturnType<
  typeof useProfileUserLastOpenedLootboxQuery
>;
export type ProfileUserLastOpenedLootboxLazyQueryHookResult = ReturnType<
  typeof useProfileUserLastOpenedLootboxLazyQuery
>;
export type ProfileUserLastOpenedLootboxQueryResult = Apollo.QueryResult<
  ProfileUserLastOpenedLootboxQuery,
  ProfileUserLastOpenedLootboxQueryVariables
>;
export const ProfileUserFavoriteLootboxesDocument = gql`
  query ProfileUserFavoriteLootboxes($user_id: uuid!, $limit: Int!) {
    Profile__UserFavoriteLootboxes(user_id: $user_id, limit: $limit) {
      user_favorite_lootboxes {
        lootbox_id
        lootbox_name
        lootbox_image_link
        lootbox_price
        lootbox_slug
      }
      error
      success
    }
  }
`;

/**
 * __useProfileUserFavoriteLootboxesQuery__
 *
 * To run a query within a React component, call `useProfileUserFavoriteLootboxesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileUserFavoriteLootboxesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileUserFavoriteLootboxesQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProfileUserFavoriteLootboxesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProfileUserFavoriteLootboxesQuery,
    ProfileUserFavoriteLootboxesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProfileUserFavoriteLootboxesQuery,
    ProfileUserFavoriteLootboxesQueryVariables
  >(ProfileUserFavoriteLootboxesDocument, options);
}
export function useProfileUserFavoriteLootboxesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfileUserFavoriteLootboxesQuery,
    ProfileUserFavoriteLootboxesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProfileUserFavoriteLootboxesQuery,
    ProfileUserFavoriteLootboxesQueryVariables
  >(ProfileUserFavoriteLootboxesDocument, options);
}
export type ProfileUserFavoriteLootboxesQueryHookResult = ReturnType<
  typeof useProfileUserFavoriteLootboxesQuery
>;
export type ProfileUserFavoriteLootboxesLazyQueryHookResult = ReturnType<
  typeof useProfileUserFavoriteLootboxesLazyQuery
>;
export type ProfileUserFavoriteLootboxesQueryResult = Apollo.QueryResult<
  ProfileUserFavoriteLootboxesQuery,
  ProfileUserFavoriteLootboxesQueryVariables
>;
export const ProfileUserTopDropsDocument = gql`
  query ProfileUserTopDrops($user_id: uuid!, $limit: Int!) {
    Profile__UserTopDrops(user_id: $user_id, limit: $limit) {
      error
      success
      user_top_drops {
        item_hash_name
        item_id
        item_image_link
        item_price
        lootbox_id
        lootbox_slug
        lootbox_name
        lootbox_image_link
        item_rarity
        item_quality
        ru_name
      }
    }
  }
`;

/**
 * __useProfileUserTopDropsQuery__
 *
 * To run a query within a React component, call `useProfileUserTopDropsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileUserTopDropsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileUserTopDropsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProfileUserTopDropsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProfileUserTopDropsQuery,
    ProfileUserTopDropsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProfileUserTopDropsQuery,
    ProfileUserTopDropsQueryVariables
  >(ProfileUserTopDropsDocument, options);
}
export function useProfileUserTopDropsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfileUserTopDropsQuery,
    ProfileUserTopDropsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProfileUserTopDropsQuery,
    ProfileUserTopDropsQueryVariables
  >(ProfileUserTopDropsDocument, options);
}
export type ProfileUserTopDropsQueryHookResult = ReturnType<
  typeof useProfileUserTopDropsQuery
>;
export type ProfileUserTopDropsLazyQueryHookResult = ReturnType<
  typeof useProfileUserTopDropsLazyQuery
>;
export type ProfileUserTopDropsQueryResult = Apollo.QueryResult<
  ProfileUserTopDropsQuery,
  ProfileUserTopDropsQueryVariables
>;
export const ProfileUserItemsDocument = gql`
  query ProfileUserItems($limit: Int!, $offset: Int!, $status: Int!) {
    Profile__UserItems(offset: $offset, limit: $limit, status: $status) {
      error
      success
      available_count
      unavailable_count
      user_items {
        from_upgrade
        item_frozen
        item_hash_name
        item_id
        user_item_id
        item_image_link
        item_lootbox_id
        item_quality
        item_rarity
        item_lootbox_name
        item_lootbox_slug
        item_lootbox_image_link
        item_start_price
        item_status
        ru_name
        upgrade_serial_data {
          count
          user_prev_item_id
          user_start_item_id
          user_start_item_price
        }
      }
    }
  }
`;

/**
 * __useProfileUserItemsQuery__
 *
 * To run a query within a React component, call `useProfileUserItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileUserItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileUserItemsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useProfileUserItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProfileUserItemsQuery,
    ProfileUserItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfileUserItemsQuery, ProfileUserItemsQueryVariables>(
    ProfileUserItemsDocument,
    options
  );
}
export function useProfileUserItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfileUserItemsQuery,
    ProfileUserItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProfileUserItemsQuery,
    ProfileUserItemsQueryVariables
  >(ProfileUserItemsDocument, options);
}
export type ProfileUserItemsQueryHookResult = ReturnType<
  typeof useProfileUserItemsQuery
>;
export type ProfileUserItemsLazyQueryHookResult = ReturnType<
  typeof useProfileUserItemsLazyQuery
>;
export type ProfileUserItemsQueryResult = Apollo.QueryResult<
  ProfileUserItemsQuery,
  ProfileUserItemsQueryVariables
>;
export const ProfileUserBoostersDocument = gql`
  query ProfileUserBoosters {
    Profile__UserBoosters {
      error
      success
      user_boosters {
        booster_count
        booster_id
        booster_image_link
        booster_title
        booster_value
      }
    }
  }
`;

/**
 * __useProfileUserBoostersQuery__
 *
 * To run a query within a React component, call `useProfileUserBoostersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileUserBoostersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileUserBoostersQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileUserBoostersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProfileUserBoostersQuery,
    ProfileUserBoostersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProfileUserBoostersQuery,
    ProfileUserBoostersQueryVariables
  >(ProfileUserBoostersDocument, options);
}
export function useProfileUserBoostersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfileUserBoostersQuery,
    ProfileUserBoostersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProfileUserBoostersQuery,
    ProfileUserBoostersQueryVariables
  >(ProfileUserBoostersDocument, options);
}
export type ProfileUserBoostersQueryHookResult = ReturnType<
  typeof useProfileUserBoostersQuery
>;
export type ProfileUserBoostersLazyQueryHookResult = ReturnType<
  typeof useProfileUserBoostersLazyQuery
>;
export type ProfileUserBoostersQueryResult = Apollo.QueryResult<
  ProfileUserBoostersQuery,
  ProfileUserBoostersQueryVariables
>;
export const ProfileUpgradeHistoryDocument = gql`
  query ProfileUpgradeHistory($limit: Int!, $offset: Int!) {
    Profile__UpgradeHistory(limit: $limit, offset: $offset) {
      error
      success
      upgrade_history {
        boosters {
          booster_id
          booster_title
          booster_image_link
        }
        chance
        koef
        result
        created_at
        start_item_data {
          item_hash_name
          item_image_link_formated
          item_quality
          item_rarity
          item_start_price
          ru_name
        }
        start_item_id
        target_item_data {
          item_hash_name
          item_image_link_formated
          item_quality
          item_rarity
          item_start_price
          ru_name
        }
        target_item_id
        target_item_price
      }
    }
  }
`;

/**
 * __useProfileUpgradeHistoryQuery__
 *
 * To run a query within a React component, call `useProfileUpgradeHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileUpgradeHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileUpgradeHistoryQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useProfileUpgradeHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProfileUpgradeHistoryQuery,
    ProfileUpgradeHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProfileUpgradeHistoryQuery,
    ProfileUpgradeHistoryQueryVariables
  >(ProfileUpgradeHistoryDocument, options);
}
export function useProfileUpgradeHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfileUpgradeHistoryQuery,
    ProfileUpgradeHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProfileUpgradeHistoryQuery,
    ProfileUpgradeHistoryQueryVariables
  >(ProfileUpgradeHistoryDocument, options);
}
export type ProfileUpgradeHistoryQueryHookResult = ReturnType<
  typeof useProfileUpgradeHistoryQuery
>;
export type ProfileUpgradeHistoryLazyQueryHookResult = ReturnType<
  typeof useProfileUpgradeHistoryLazyQuery
>;
export type ProfileUpgradeHistoryQueryResult = Apollo.QueryResult<
  ProfileUpgradeHistoryQuery,
  ProfileUpgradeHistoryQueryVariables
>;

export type GetUserTermsAndDepositsQuery = {
  __typename?: 'Query';
  Profile__UserTermsAndDeposits?: {
    __typename?: 'UserTermsAndDepositsResponse';
    success: boolean;
    error?: string | null;
    terms_accepted: boolean;
    terms_accepted_at: string;
    total_deposits_count: number;
  } | null;
};

export type GetUserTermsAndDepositsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;
/**
 * __useGetWelcomeMissionsQuery__
 *
 * To run a query within a React component, call `useGetWelcomeMissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWelcomeMissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWelcomeMissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserTermsAndDepositsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserTermsAndDepositsQuery,
    GetUserTermsAndDepositsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserTermsAndDepositsQuery,
    GetUserTermsAndDepositsQueryVariables
  >(GetUserTermsAndDeposits, options);
}
export function useGetUserTermsAndDepositsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserTermsAndDepositsQuery,
    GetUserTermsAndDepositsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserTermsAndDepositsQuery,
    GetUserTermsAndDepositsQueryVariables
  >(GetUserTermsAndDeposits, options);
}
export type GetUserTermsAndDepositsQueryHookResult = ReturnType<
  typeof useGetUserTermsAndDepositsQuery
>;
export type GetUserTermsAndDepositsLazyQueryHookResult = ReturnType<
  typeof useGetUserTermsAndDepositsLazyQuery
>;
export type GetUserTermsAndDepositsQueryResult = Apollo.QueryResult<
  GetUserTermsAndDepositsQuery,
  GetUserTermsAndDepositsQueryVariables
>;

export const GetUserTermsAndDeposits = gql`
    query GetUserTermsAndDeposits {
        Profile__UserTermsAndDeposits {
            terms_accepted
            terms_accepted_at
            total_deposits_count
            success
            error
        }
    }
`;