import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';
import styles from './OvalButton.module.scss';

type IOvalButton = ButtonProps & {
  loading?: boolean;
  className?: string;
  icon: JSX.Element;
  small?: boolean;
  count?: number;
  light?: boolean;
};

export default function OvalButton({
  loading,
  className,
  icon,
  small,
  count,
  light,
  ...props
}: IOvalButton) {
  return (
    <>
      <Button
        className={`${small ? styles.small : styles.default} ${className}`}
        disabled={props.disabled || loading == true}
        style={{
          background: light ? '#191F40' : '#0C1027',
        }}
        sx={{
          '&&:hover': {
            background: light ? '#252E5C !important' : '#191F40  !important',
          },
        }}
        {...props}
      >
        {loading ? (
          <CircularProgress
            style={{
              color: '#d3f85a',
              width: '25px',
              height: '25px',
            }}
          />
        ) : (
          <>
            {icon}
            {Number.isInteger(count) && (
              <div className={styles.count_container}>
                <span className={styles.count_text}>{count}</span>
              </div>
            )}
          </>
        )}
      </Button>
    </>
  );
}
